import { configureStore } from '@reduxjs/toolkit';
import { favoritesAssignWidgetReducer } from '@cian/favorites-assign-widget';

import {
  recommendationsReducer,
  showHeaderReducer,
  landingTypeReducer,
  regionIdReducer,
  authenticationReducer,
} from '../../slices';
import { IApplicationContext } from '../../types';

export function createReduxStore(context: IApplicationContext, preloadedState: object, debug: boolean) {
  return configureStore({
    reducer: {
      recommendations: recommendationsReducer,
      showHeader: showHeaderReducer,
      landingType: landingTypeReducer,
      regionId: regionIdReducer,
      authentication: authenticationReducer,
      favoritesAssignWidget: favoritesAssignWidgetReducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: { extraArgument: context },
      }),
    devTools: debug,
    preloadedState,
  });
}
